<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Multiselect from "vue-multiselect";
import ApiService from "@/services/jobsiteTrackingService";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "user",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Multiselect,
    //PageHeader,
    Loading
  },
  data() {
    return {
      title: "Users",
      isLoading: false,
      items: [
        {
          text: "users",
          href: "/users",
        },
        {
          text: "",
          active: true,
        },
      ],
      roles: [
        "admin",
        "user"
      ],
      userConfig: {
        id: null,
        displayName: null,
        role: null,
        shippers: [],
        installers: []
      },
      shippers: [],
      installers: [],
      userConfigDiscardCopy: null
    };
  },
  beforeCreate() {
  },
  created() {
    let userId = this.$route.params.userId;
    this.isLoading = true;
    var userPromise = ApiService.getUser(userId).then((response) => {
      this.userConfig = response.data.data;

      this.userConfigDiscardCopy = JSON.parse(JSON.stringify(this.userConfig));
    });
    var shipperPromise = ApiService.getShippers().then((response) => {
      this.shippers = response.data.data;
    });
    var installerPromise = ApiService.getInstallers().then((response) => {
      this.installers = response.data.data;
    });
    Promise.all([userPromise, shipperPromise, installerPromise]).then(() => {
      if (this.userConfig.shippers != null) {
        for (var i = 0; i < this.userConfig.shippers.length; i++) {
          this.userConfig.shippers[i].name = this.shippers.find(x => x.idPublic === this.userConfig.shippers[i].idPublic)?.name
        }
      }
      if (this.userConfig.installers != null) {
        for (var j = 0; j < this.userConfig.installers.length; j++) {
          this.userConfig.installers[j].name = this.installers.find(x => x.idPublic === this.userConfig.installers[j].idPublic)?.name
        }
      }
    }).finally(() => {
      this.isLoading = false;
    });
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    saveUserConfig() {
      this.isLoading = true;

      var userConfigCopy = JSON.parse(JSON.stringify(this.userConfig));
      ApiService.saveUserConfig(userConfigCopy).then(() => {
        this.isLoading = false;
        this.$store.dispatch("userconfig/clearUserConfig");
        this.$router.push({ name: 'users' });
      })
    },
    discard() {
      this.userConfig = JSON.parse(JSON.stringify(this.userConfigDiscardCopy));
    }
  }
};
</script>

<template>
  <Layout>
    <div style="flex: 1 1 1px; display: flex; flex-direction: column; margin:0 -24px -60px -24px;">
      <div class="p-2 pl-4 pr-4 pb-2" style="padding-right: 2.5rem !important; padding-bottom:0 !important;">
        <div style="padding: 20px; background: white; border-bottom: 1px solid rgba(204,204,204,.8);">
          <b-button @click="saveUserConfig" variant="success" class="mr-2">{{ $t('general.save') }}</b-button>
          <b-button @click="discard" variant="light">{{ $t('general.discard') }}</b-button>
        </div>
      </div>

      <div class="p-2 pl-4 pr-4" style="flex:1 1 1px; min-height:0; overflow-y:scroll; position:relative;">
        <div class="card">
          <div class="card-body" style="position:relative;">
            <loading :active.sync="isLoading" :can-cancel="false" color='#f1b44c' :width=70 :height=70 loader='dots'
              :is-full-page="false">
            </loading>
            <div v-if="userConfig.userId != null">
              <h2 class="mb-4">{{ userConfig.displayName }}</h2>
              <div class="mb-4">
                <h5>{{ $t('general.role') }}</h5>
                <ul style="padding-left:15px; list-style-type:square;">
                  <li>
                    <small>{{ $t('general.adminRoleText') }}</small>
                  </li>
                  <li>
                    <small>{{ $t('general.userRoleText') }}</small>
                  </li>
                </ul>
                <multiselect style="max-width:250px;" v-model="userConfig.role" :show-labels="false" :options="roles">
                </multiselect>
              </div>
            </div>
            <div class="mb-4">
              <b-form-checkbox size="lg" switch v-model="userConfig.canCheckAll">{{ $t('general.cancheckall')
              }}</b-form-checkbox>
            </div>
            <div class="mb-4">
              <b-form-checkbox size="lg" switch v-model="userConfig.canCompleteStep">{{ $t('general.cancompletestep')
              }}</b-form-checkbox>
            </div>
            <div class="mb-4">
              <h5>{{ $t('general.shippers') }}</h5>
              <multiselect style="max-width:500px;" v-model="userConfig.shippers" :show-labels="false" :options="shippers"
                label="name" track-by="idPublic" :multiple="true">
              </multiselect>
            </div>
            <div class="mb-4">
              <h5>{{ $t('general.installers') }}</h5>
              <multiselect style="max-width:500px;" v-model="userConfig.installers" :show-labels="false"
                :options="installers" label="name" track-by="idPublic" :multiple="true">
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
